import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import { useMacroeconomicsClient } from "./client";
import {
    GDPChartList,
    CPIChartList,
    InterestRateChartList,
    IndustryChartList,
    RetailChartList,
    TradeChartList,
    FDIChartList
} from "./utils";
import ChartCard from "../../../../../../components/CustomComposedChart";
import * as config from "../../../../../../config";
import Skeleton from "../../../../../../components/Skeleton";

const MacroeconomicsContainer = (props) => {
    let { glContainer } = props;
    let macroeconomicsClient = useMacroeconomicsClient();
    const { t } = useTranslation();

    const [ macroeconomics, setMacroeconomics ] = useState<NSApp.Macroeconomics>();
    const [ loading, setLoading ] = useState(true);

    const fetchMacroeconomics = async () => {
        let [err, resp] = await macroeconomicsClient.fetchMacroeconomics();

        if (err) return;

        setMacroeconomics(resp);
        setLoading(false);
    };

    useEffect(() => {
        fetchMacroeconomics();
    }, []); // eslint-disable-line

    const getTabs = () => {
        return [
            { key: "gdp", title: t('GDP - PMI'), data: GDPChartList(macroeconomics), column: 4, row: 3 },
            { key: "cpi", title: t('Lạm phát - CPI'), data: CPIChartList(macroeconomics), column: 1, row: 2 },
            { key: "interestRate", title: t('Tiền tệ - Lãi suất'), data: InterestRateChartList(macroeconomics), column: 4, row: 3 },
            { key: "industry", title: t('Công nghiệp'), data: IndustryChartList(macroeconomics), column: 3, row: 2 },
            { key: "retail", title: t('Bán lẻ - Tiêu dùng'), data: RetailChartList(macroeconomics), column: 1, row: 2 },
            { key: "trade", title: t('Xuất nhập khẩu'), data: TradeChartList(macroeconomics), column: 1, row: 2 },
            { key: "fdi", title: t('Đầu tư nước ngoài'), data: FDIChartList(macroeconomics), column: 1, row: 2 }
        ];
    };

    return (
        <div className="overflow-y-auto px-1" style={{ height: glContainer.height }}>
            <Tabs defaultActiveKey="gdp">
                {getTabs().map(tab => {
                    const { key, title, data, column, row } = tab;
                    let chartWidth = glContainer.width > config.RESPONSIVE_SCREEN.lg ? (glContainer.width - 100) / column : (glContainer.width - 100) / 2;
                    let chartHeight = (glContainer.height - 85) / row - (row === 3 ? 36 : 29);

                    if (loading) {
                        return <Tabs.TabPane key={key} tab={title}>
                            <div style={{height: glContainer.height/3-20}}><Skeleton column={3}/></div>
                            <div style={{height: glContainer.height/3-20}}><Skeleton column={3}/></div>
                            <div style={{height: glContainer.height/3-20}}><Skeleton column={3}/></div>
                        </Tabs.TabPane>
                    }

                    return <Tabs.TabPane key={key} tab={title}>
                        <Row gutter={[4, 4]}>
                            {data.map(item => {
                                return <Col span={24/column} key={item.title}>
                                    <ChartCard
                                        title={item.title}
                                        width={chartWidth}
                                        height={chartHeight}
                                        data={item.data}
                                        timeType={item.timeType}
                                        chartType={item.chartType}
                                        chartStructure={item.chartStructure}
                                        YAxisUnit={item.YAxisUnit}
                                        secondYAxis={item.secondYAxis}
                                        secondYAxisUnit={item.secondYAxisUnit}
                                        replaceMonthToQuarter={item.replaceMonthToQuarter}
                                        tooltipOutsideBoxPosition={item.tooltipOutsideBoxPosition}
                                    />
                                </Col>
                            })}
                        </Row>
                    </Tabs.TabPane>
                })}
            </Tabs>
        </div>
    )
};

export default MacroeconomicsContainer;