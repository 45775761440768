import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Typography, Card, Row, Col, Tabs } from 'antd';
import { useTranslation } from "react-i18next";

import InviteFriend from './InviteFriend';
import FpointHistory from './FpointHistory';

import themeModeGlobalState from "../../../../../contexts/ThemeMode";

import api from "../../../../../services/api";

const { Text } = Typography;

type MyPoint = {
    point: number,
    pointReceived: number,
    totalInvited: number
};

const Fpoint = () => {
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const [point, setPoint] = useState<MyPoint>();

    // const fetchMyPoint = async () => {
    //     const [error, response]: any = await api.get('/point/me');

    //     if (error) {
    //         return;
    //     }

    //     setPoint(response.data);
    // }

    // useEffectOnce(() => {
    //     fetchMyPoint();
    // });

    return (
        <div style={{ overflow: 'scroll' }}>
            <Card className="m-auto mt-5 mb-5">
                <Row>
                    <Col className="text-center" span={6}>
                        <Text>{t('Fpoint khả dụng')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong type="warning">{point?.point}</Text>
                        </div>
                    </Col>
                    <Col className="text-center" span={6}>
                        <Text>{t('Tổng số người đã mời')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong>{point?.totalInvited}</Text>
                        </div>
                    </Col>
                    <Col className="text-center" span={6}>
                        <Text>{t('Fpoint đã nhận')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong>{point?.pointReceived}</Text>
                        </div>
                    </Col>
                    <Col className="text-center" span={6}>
                        <Text>{t('Fpoint đã sử dụng')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong>
                                {point?.pointReceived && point?.point ? point?.pointReceived - point?.point : 0}
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Card>

            <Tabs defaultActiveKey="fpoint_history">
                <Tabs.TabPane tab={`${t('Lịch sử Fpoint')}`} key="fpoint_history">
                    <FpointHistory point={point} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`${t('Danh sách người đã mời')}`} key="user_invited">
                    <InviteFriend />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
};

export default Fpoint;