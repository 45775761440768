import React, { useState } from "react";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";

import Loading from '../../../../../components/Loading';
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import api from "../../../../../services/api";

const Affiliate = () => {
    const [tabKey , setUserTabKey ] = useState<string>("profile");
    const [userAffiliate, setUserAffiliateProfile] = useState<NSApp.UserAffiliate>();

    const { t } = useTranslation();

    const fetchAffiliateProfile = async () => {
        const [error, response]: any = await api.get('/affiliate/me');

        if (error) {
            return;
        }

        setUserAffiliateProfile(response.data);
    };

    useEffectOnce(() => {
        fetchAffiliateProfile();
    });

    const tabList = [
        {
            key: "profile",
            tab: t('Hồ sơ'),
        },
        {
            key: "dashboard",
            tab: t('Hoa hồng'),
        },
    ];

    const contentList = {
        profile: userAffiliate ? <Profile affiliate={userAffiliate} onSuccessSubmited={fetchAffiliateProfile}/> : <Loading />,
        dashboard: userAffiliate ? <Dashboard affiliate={userAffiliate} /> : <Loading />,
    };

    const onTabChange = async (key) => {
        setUserTabKey(key);
    };

    return (
        <Card
            className="h-full"
            bodyStyle={{ overflow: 'scroll' }}
            tabList={tabList}
            activeTabKey={tabKey}
            onTabChange={(key) => onTabChange(key)}
        >
            {contentList[tabKey]}
        </Card>
    );
};

export default Affiliate;
