import React from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import UserACL from '../../../../../../components/UserACL';
// import DevelopingScreen from "../../../../../../components/DevelopingScreen";
import Breakout from "./container/breakout";
import IndustryStatistics from "./container/industryStats";
import EtfPortfolio from "./container/etfPortfolio";
import InvestmentPerformance from "./container/investmentPerform"

const StocksScreenerWindow = (props) => {
    let { glContainer } = props;
    const { t } = useTranslation();

    return (
        <UserACL feature="stocks_screener">
            <div className="overflow-y-auto px-1" style={{ height: glContainer.height }}>
                <Tabs defaultActiveKey="breakout">
                    <Tabs.TabPane key="breakout" tab={t('Cổ phiếu đột phá')}>
                        <Breakout glContainer={glContainer} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="industry_stats" tab={t('Thống kê ngành')}>
                        <IndustryStatistics glContainer={glContainer} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="etf_portfolio" tab={t('Danh mục quỹ đầu tư')}>
                        <UserACL feature="stocks_screener_etf" userACLName = "Chuyên nghiệp">
                            <EtfPortfolio glContainer={glContainer} />
                        </UserACL>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="investment-performent" tab={t('Hiệu suất đầu tư')}>
                        <UserACL feature="investment_performance" userACLName = "Chuyên nghiệp">
                            <InvestmentPerformance glContainer={glContainer}/>
                        </UserACL>
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane key="screener" tab={t('Bộ lọc cổ phiếu')}>
                        <DevelopingScreen height={glContainer.height - 50} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="growth" tab={t('Cổ phiếu tăng trưởng')}>
                        <DevelopingScreen height={glContainer.height - 50} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="value" tab={t('Cổ phiếu cơ bản')}>
                        <DevelopingScreen height={glContainer.height - 50} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="momentum" tab={t('Cổ phiếu đầu cơ')}>
                        <DevelopingScreen height={glContainer.height - 50} />
                    </Tabs.TabPane> */}
                </Tabs>
            </div>
        </UserACL>
    )
};

export default StocksScreenerWindow;