import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, DatePicker, Modal, Typography, Tag, Col, Row, Card } from 'antd';
import { useTranslation } from "react-i18next";
import moment from 'moment';

const { Text, Paragraph } = Typography;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type AffiliateProfileProps = {
    affiliate: NSApp.UserAffiliate,
};

const AdminAffiliateProfile = (props: AffiliateProfileProps) => {
    const { affiliate } = props;

    const [form] = Form.useForm();

    let affiliateStatusStr = '';

    if (affiliate.status === 'not-submited') {
        affiliateStatusStr = 'Chưa đăng ký';
    }

    if (affiliate.status === 'approved') {
        affiliateStatusStr = 'Đã xác nhận';
    }

    if (affiliate.status === 'rejected') {
        affiliateStatusStr = 'Từ chối';
    }

    if (affiliate.status === 'processing') {
        affiliateStatusStr = 'Đang xử lý';
    }

    const initialValues = {
        email: affiliate.email || '',
        taxCode: affiliate.taxCode || '',
        name: affiliate.name || '',
        phone: affiliate.phone || '',
        identityNumber: affiliate.identityNumber || '',
        dateOfBirth: moment(affiliate.dateOfBirth) || '',
        identityDate: moment(affiliate.identityDate) || '',
        identityPlace: affiliate.identityPlace || '',
        address: affiliate.address || '',
        bankName: affiliate.bankName || '',
        bankBranch: affiliate.bankBranch || '',
        bankAccountName: affiliate.bankAccountName || '',
        bankAccountNumber: affiliate.bankAccountNumber || ''
    }

    useEffect(() => form.resetFields(), [form, initialValues]);

    const readOnly = true;

    return (
        <Row gutter={16}>
            <Col span={12}>
                <Form
                    {...layout}
                    form={form}
                    className="my-4 max-w-screen-sm"
                    size="middle"
                    name="updateInfo"
                    labelAlign="left"
                    initialValues={initialValues}
                >
                    <Form.Item label='Tình trạng hồ sơ'>
                        <span className='text-primary'>{affiliateStatusStr}</span>
                    </Form.Item>
                    <Form.Item label='Họ Tên' name="name">
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Điện thoại' name="phone">
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Email' name="email">
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Mã số thuế' name="taxCode" >
                        <Input readOnly={readOnly} />
                    </Form.Item>


                    <Form.Item label='Số CMND' name='identityNumber'>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Ngày sinh' name="dateOfBirth">
                        <DatePicker placeholder="" style={{ width: '100%'}} disabled={readOnly} />
                    </Form.Item>

                    <Form.Item label='Ngày cấp' name="identityDate" >
                        <DatePicker placeholder="" style={{ width: '100%'}} disabled={readOnly} />
                    </Form.Item>

                    <Form.Item label='Nơi cấp' name="identityPlace" >
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Địa chỉ liên hệ' name="address" >
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Tên ngân hàng' name="bankName" >
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Chi nhánh ngân hàng' name="bankBranch">
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Tên chủ tài khoản' name="bankAccountName" >
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label='Số tài khoản' name="bankAccountNumber">
                        <Input readOnly={readOnly} />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default AdminAffiliateProfile;