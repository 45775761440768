import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Typography, Card, Button, Row, Col, Tooltip, message, Tabs, Modal, Image, Space, InputNumber } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { useTranslation } from "react-i18next";

import InviteFriend from './InviteFriend';
import FpointHistory from './FpointHistory';

import LogoWhite from "../../../../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../../../../assets/image/logo/Fstock_black.svg";
import themeModeGlobalState from "../../../../../contexts/ThemeMode";

import * as config from "../../../../../config";
import { useAuthState } from "../../../../../contexts";
import api from "../../../../../services/api";

const { Text, Title, Paragraph } = Typography;

type MyPoint = {
    point: number,
    pointReceived: number,
    totalInvited: number
};

const Fpoint = () => {
    const { authState } = useAuthState() as any;
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();
    const isDarkMode = themeMode === 'dark';
    const FstockLogo = isDarkMode ? LogoWhite : LogoBlack;

    const [ModalResult, contextHolder] = Modal.useModal();

    const [ isVisibleModalBuyItem, setIsVisibleModalBuyItem ] = useState<boolean>(false);
    const [myPoint, setMyPoint] = useState<MyPoint>();
    const [shopItems, setShopItems] = useState<NSApp.ShopItem[]>([]);
    const [itemSelected, setItemSelected] = useState<NSApp.ShopItem>();
    const [itemQuantity, setItemQuantity] = useState<number>(0);
    const [maxItemQuantity, setMaxItemQuantity] = useState<number>(0);

    const currentUser = authState.currentUser;
    const userACL = currentUser.userACL;

    const inviteCode = currentUser.code;
    const inviteLink = `${config.WEB_BASE_URL}/user/register?code=${inviteCode}`;

    const copyCode = () => {
        copy(inviteCode);
        message.success(`${t('Đã sao chép mã giới thiệu')}: ${inviteCode}`, 3);
    };

    const copyInviteLink = () => {
        copy(inviteLink);
        message.info(`${t('Đã sao chép đường dẫn giới thiệu')}: ${inviteLink}`, 3);
    };

    const fetchMyPoint = async () => {
        const [error, response]: any = await api.get('/point/me');

        if (error) {
            return;
        }

        setMyPoint(response.data);
    }

    const fetchShopItems = async () => {
        const [error, response]: any = await api.get('/point/shop-items');

        if (error) {
            return;
        }

        setShopItems(response.data);
    }

    const resetStateAfterBuyItemSuccessfully = async () => {
        setIsVisibleModalBuyItem(false);
        setItemQuantity(0);
        setMaxItemQuantity(0);
        setItemSelected(undefined);
        await fetchMyPoint();
    }

    const buyItem = async () => {
        if (!itemSelected) {
            message.error(t('Bạn chưa chọn gói muốn đổi.'));
            return;
        }

        if (itemQuantity < 1) {
            message.error(t('Số lượng phải lớn hơn hoặc bằng 1'));
            return;
        }

        if (itemQuantity > maxItemQuantity) {
            message.error(t('Số lượng lớn hơn số lượng tối đa có thể mua.'));
            return;
        }

        const [error, ]: any = await api.post('/point/buy-item', {
            itemId: itemSelected.id,
            quantity: itemQuantity,
        });

        if (error) {
            message.error(t(error.response.data.message))
            return;
        }

        ModalResult.success({
            content: (
                <Text>
                    {t('Quy đổi thành công, bạn được cộng thêm')} {itemQuantity} {t('ngày sử dụng')} <Text strong>{itemSelected.name}</Text>
                </Text>
            ),
        });

        await resetStateAfterBuyItemSuccessfully();
    }

    const onItemChange = (value: number) => {
        setItemQuantity(value);
    };

    useEffectOnce(() => {
        fetchMyPoint();
        fetchShopItems();
    });

    return (
        <div style={{ overflow: 'scroll' }}>
            <Row>
                <Col span={6}>{currentUser.fullname}</Col>
                <Col span={6}>{t('Gói hiện tại:')} {userACL.plan}</Col>
                <Col span={6}>
                    <Text>{t('Mã giới thiệu')}: </Text>
                    <Text strong>{inviteCode}</Text>
                    <Tooltip title={t('Sao chép')} className="ml-2">
                        <Button onClick={copyCode} icon={<CopyOutlined />} />
                    </Tooltip>
                </Col>
                <Col span={6}>
                    <Text>{t('Link giới thiệu')}: </Text>
                    <Text strong>{`https://...${inviteCode}`}</Text>
                    <Tooltip title={t('Sao chép')}  className="ml-2">
                        <Button onClick={copyInviteLink} icon={<CopyOutlined />} />
                    </Tooltip>
                </Col>
            </Row>

            <Card className="m-auto mt-5 mb-5">
                <Row>
                    <Col className="text-center" span={6}>
                        <Text>{t('Fpoint khả dụng')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong type="warning">{myPoint?.point}</Text>
                        </div>
                    </Col>
                    <Col className="text-center" span={6}>
                        <Text>{t('Tổng số người đã mời')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong>{myPoint?.totalInvited}</Text>
                        </div>
                    </Col>
                    <Col className="text-center" span={6}>
                        <Text>{t('Fpoint đã nhận')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong>{myPoint?.pointReceived}</Text>
                        </div>
                    </Col>
                    <Col className="text-center" span={6}>
                        <Text>{t('Fpoint đã sử dụng')}</Text>
                        <div>
                            <Text style={{ fontSize: 50 }} strong>
                                {myPoint?.pointReceived && myPoint?.point ? myPoint?.pointReceived - myPoint?.point : 0}
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Card>

            <Card className="m-auto mb-5" style={{ width: 500 }}>
                <Text>{t('Quy đổi Fpoint')}</Text>
                <div className="mt-5 text-center">
                    <Row>
                        {shopItems.map(item => {
                            return (
                                <Col span={12}>
                                    <Button
                                        style={{ width: 200, height: 100, fontSize: 20, fontWeight: 'bold' }}
                                        type="primary" shape="round"
                                        onClick={() => {

                                            if (userACL.key === 'professional' && item.id === 'plan-advanced') {
                                                message.error(t('Không thể quy đổi Fpoint sang gói thấp hơn gói đang sử dụng'));
                                                return;
                                            }

                                            setIsVisibleModalBuyItem(!isVisibleModalBuyItem);
                                            setItemSelected(item);
                                            if (myPoint?.point) {
                                                const maxItemQuantity = Math.floor(myPoint.point / item.price);
                                                setMaxItemQuantity(maxItemQuantity);
                                                setItemQuantity(maxItemQuantity);
                                            }
                                        }}
                                    >
                                        <Text>{item.name}</Text>
                                    </Button>
                                    <div><Text strong>{t('1 ngày = {{price}} points', { price: `${item.price}` })}</Text></div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </Card>

            <Tabs defaultActiveKey="fpoint_history">
                <Tabs.TabPane tab={`${t('Lịch sử Fpoint')}`} key="fpoint_history">
                    <FpointHistory myPoint={myPoint} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={`${t('Danh sách người đã mời')}`} key="user_invited">
                    <InviteFriend />
                </Tabs.TabPane>
            </Tabs>

            <Modal width={800} visible={isVisibleModalBuyItem} onCancel={() => setIsVisibleModalBuyItem(false)} destroyOnClose footer={null}>
                <Row>
                    <Col span={12}>
                        <Image
                            width={200}
                            src={FstockLogo}
                        />
                    </Col>
                    <Col span={12}>
                        <Space direction="vertical">
                            <Title level={3}>{t('Quy đổi Fpoint thành ngày sử dụng')}</Title>
                            <Text strong>{t('1 ngày = {{price}} points', { price: `${itemSelected?.price}` })}</Text>
                            <Text>{t('Fpoints bạn đang có')}: {myPoint?.point}</Text>
                            <div>
                                <Text>{t('Số ngày tối đa có thể đổi:')} {maxItemQuantity}</Text>
                                <div className="mt-1">
                                    <Text className="mr-2">{t('Số ngày muốn đổi')}:</Text>
                                    <InputNumber min={1} defaultValue={maxItemQuantity}  onChange={onItemChange} />
                                </div>
                                <Button className="mt-5 w-full" type="primary" onClick={buyItem}>{t('Đổi')}</Button>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <div>
                    <Paragraph>{t('Ghi chú, người dùng có thể :')}</Paragraph>
                    <Paragraph>{t('Quy đổi Fpoint thành số ngày sử dụng của gói hiện tại. Số ngày quy đổi được cộng thêm vào thời gian sử dụng của gói hiện tại.')}</Paragraph>
                    <Paragraph>{t('Quy đổi Fpoint thành số ngày sử dụng của gói cao hơn gói hiện tại. Gói hiện tại được nâng cấp lên gói cao hơn. Số ngày sử dụng được tính bằng số ngày còn lại của gói hiện tại quy đổi thành gói cao hơn, cộng với số ngày đã quy đổi từ Fpoint.')}</Paragraph>
                </div>
            </Modal>
            {contextHolder}
        </div>
    )
};

export default Fpoint;