export const DEFAULT_DASHBOARDS = {
    'macroeconomics': {
        content: [{
            type: 'stack',
            title: "",
            header: {},
            content: [
                {
                    type: "component",
                    title: "Thống kê các chỉ tiêu kinh tế",
                    component: "macroeconomics_statistics",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
            ]
        }]
    },
    'derivatives': {
        content: [{
            type: 'stack',
            title: "",
            header: {},
            content: [
                {
                    type: "component",
                    title: "Bảng giá phái sinh",
                    component: "table_price",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false,
                    props: { activeTab: 'derivatives', isDerivativesDashboard: true }
                },
                {
                    type: "component",
                    title: "Phân tích phái sinh",
                    component: "derivatives_analysis",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                }
            ]
        }]
    },
    'market_analysis': {
        settings:{
            reorderEnabled: false,
            selectionEnabled: false,
            showPopoutIcon: false,
            showMaximiseIcon: false,
            showCloseIcon: false
        },
        content: [{
            type: 'stack',
            title: "",
            header: {},
            content: [
                {
                    type: "component",
                    title: "Bảng giá",
                    component: "table_price",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Toàn cảnh thị trường",
                    component: "stock_market_overview",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false,
                },
                {
                    type: "component",
                    title: "Bản đồ nhiệt",
                    component: "heat_map",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Giao dịch khối ngoại",
                    component: "foreigner_trading",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Giao dịch tự doanh",
                    component: "proprietary",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Bộ lọc cổ phiếu",
                    component: "stocks_screener",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Thông tin cổ phiếu",
                    component: "stock_detail",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Phân tích kỹ thuật",
                    component: "tradingview",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Danh mục quan tâm",
                    component: "favourites",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Phân tích phái sinh",
                    component: "derivatives_analysis",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: true
                },
                {
                    type: "component",
                    title: "Biến động chỉ số chính",
                    component: "main_index",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Thống kê thị trường chứng khoán",
                    component: "market_reports",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
                {
                    type: "component",
                    title: "Truy vết dòng tiền",
                    component: "cash_flow",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: false
                },
            ]
        }]
    },
    'news': {
        content: [{
            type: 'stack',
            title: "",
            header: {},
            content: [
                {
                    type: "component",
                    title: "Tin tổng hợp",
                    component: "news",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: true
                },
            ]
        }]
    },
    'articles': {
        content: [{
            type: 'stack',
            title: "",
            header: {},
            content: [
                {
                    type: "component",
                    title: "Bài viết",
                    component: "articles",
                    isClosable: false,
                    componentName: "lm-react-component",
                    reorderEnabled: true
                },
            ]
        }]
    },
};
