import React from "react";
import UserACL from '../../../../../../components/UserACL';
import CashFlowContainer from "./container";

const CashFlowWindow = (props) => {
    let { glContainer } = props;

    return (
        // <UserACL feature="cashflow">
            <CashFlowContainer glContainer={glContainer} />
        // </UserACL>
    )
};

export default CashFlowWindow;