import React, { useState } from "react";
import InvestmentPerformanceTable from "../components/InvestmentPerformTable";
import { Button, DatePicker, Space } from "antd";
import { useEffectOnce } from "react-use";
import api from "../../../../../../../services/api";

const InvestmentPerformance = (props) => {
    const { glContainer } = props;

    const [dateRange, setDateRange] = useState<[string, string]>();

    const [loading, setLoading] = useState(true);

    const onChangeRangePicker = (date: any, dateString: [string, string]) => {
        setDateRange(dateString);
    }

    const [investmentPerform, setInvestmentPerform] = useState([])
    const fetchInvestmentPerformance = async () => {
        let [error, response] :any = await api.get(`/investment-performance`,{
            page: 1,
            pageSize: 1000,
            startDate: dateRange ? dateRange[0] : null,
            endDate: dateRange ? dateRange[1] : null,
        })
        setInvestmentPerform(response.data.stockInvestmentPerformances)
        if(response){
            setLoading(false)
        }
    }

    useEffectOnce(() => {
        fetchInvestmentPerformance()
    })
    
    return(
        <div>
            <div style={{ display:'flex', justifyContent:'flex-end' }}>
                <Space>
                    <DatePicker.RangePicker 
                        picker="date" 
                        onChange={onChangeRangePicker} 
                        format="DD-MM-YYYY" 
                        allowEmpty={[true,true]}
                    />
                    <Button type="primary" onClick={fetchInvestmentPerformance}>Tìm kiếm</Button>
                </Space>
            </div>
            <InvestmentPerformanceTable
                layoutHeight={glContainer.height}
                data={investmentPerform}
                loading={loading}
            />
        </div>
    )
}

export default InvestmentPerformance