import React, { useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import { useContextSelector } from "use-context-selector";
import { StockContext } from "../../../../../../../contexts/StocksProvider";
import NameWithChartIcon from "../../../../components/NameWithChartIcon";
import { getStockColorClass } from "../../../../../../../services/utils";
import { localeFormat, priceChangePercentFormat, priceFormat } from "../../../../../../../services/stock-price";
import { useTranslation } from "react-i18next";
import moment from "moment";
import theme from "../../../../../../../themes/theme";

interface InvestPerform {
    id: number,
    foreignvolumeroom: number;
    freefloatpercent: number;
    foreignpercent: number;number
    sharesoutstanding: number;
    marketcap: number;
    roarecent: number;
    roerecent: number;
    pb: number;
    pe: number;
    bookvalue: number;
    eps: number;
    recommendedDate: Date,
    buyPrice: number,
    pricePercentChange: number,
    stockCode: string,
}

const CellValueHasColor = ({ value, stock, columnKey }) => {
    const [themeMode,] = themeModeGlobalState();
    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][stock.stockCode],
    );
    
    const _stockData = stockState ? stockState : stock;
    if (columnKey === 'stockCode') {
        return <NameWithChartIcon
            color={getStockColorClass(_stockData, themeMode)}
            symbol={_stockData.stockCode.toUpperCase()}
        />
    }

    if (columnKey === 'matchedPrice') {
        return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>
            {localeFormat(_stockData.matchedPrice)}
        </span>
    }
    
    if (columnKey === 'profitPercent') {
        const profitPercent = (_stockData.matchedPrice - value)/value * 100;
        return <span style={{ 
            color: profitPercent > 0 ? theme.stockColors.increase[themeMode] : theme.stockColors.reduced[themeMode], 
            fontWeight: 'bold' }}
            >
            {profitPercent > 0 && '+'}
            {`${priceChangePercentFormat(profitPercent)}%`}
        </span>
    }
    
    if (columnKey === 'pricePercentChange') {
        return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>
            {_stockData.priceChange > 0 && '+'}
            {`${priceChangePercentFormat(_stockData.pricePercentChange)}%`}
        </span>
    }

    return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>{localeFormat(value)}</span>
};

const InvestmentPerformanceTable = (props) => {
    const { t } = useTranslation();

    const { layoutHeight, data, criteriaExpanded, loading } = props;

    const _criteriaHeight = criteriaExpanded ? 421 : 144;
    const tableContentHeight = layoutHeight - _criteriaHeight > 300 ? layoutHeight - _criteriaHeight : 300;

    const renderNormalValue = (value) => {
        return <span className="font-bold">{localeFormat(value)}</span>;
    }

    const renderPercentFormat = (value) => {
        return <span className="font-bold">{priceChangePercentFormat(value)}%</span>;
    }

    const renderColorText = (value, stock, columnKey) => {
        return <CellValueHasColor value={value} stock={stock} columnKey={columnKey} />
    }

    const renderDate = (date: any) => {
        date = moment(date).format('DD/MM/YYYY')
        return date
    }

    let columns: ColumnsType<InvestPerform> = [
        {
            width: 50,
            title: t('Mã'),
            dataIndex: 'stockCode',
            key: 'stockCode',
            showSorterTooltip: false,
            fixed: 'left',
            sorter: (a: InvestPerform, b: InvestPerform) => a.stockCode.localeCompare(b.stockCode),
            render: (value, stock) => renderColorText(value, stock, 'stockCode'),
        },
        {
            width: 50,
            title: t('Giá'),
            key: 'matchedPrice',
            dataIndex: 'matchedPrice',
            showSorterTooltip: false,
            align: 'center',
            render: (value, stock) => renderColorText(value, stock, 'matchedPrice'),
        },
        {
            width: 50,
            title: t('+/-(%)'),
            key: 'pricePercentChange',
            dataIndex: 'pricePercentChange',
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'pricePercentChange'),
        },
        {
            width: 100,
            title: t('Ngày khuyến nghị'),
            dataIndex: 'recommendedDate',
            key: 'recommendedDate',
            align: 'center',
            defaultSortOrder: 'ascend',
            sorter: (a: InvestPerform, b: InvestPerform) => a.recommendedDate < b.recommendedDate ? 1 : -1,
            render: (value) => renderDate(value),
        },
        {
            width: 50,
            title: t('Giá mua'),
            dataIndex: 'buyPrice',
            key: 'buyPrice',
            align: 'center',
            sorter: (a: InvestPerform, b: InvestPerform) => a.buyPrice - b.buyPrice,
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('% Lãi lỗ'),
            key: 'profitPercent',
            dataIndex: 'profitPercent',
            showSorterTooltip: false,
            align: 'right',
            // sorter: (a: InvestPerform, b: InvestPerform) => a.profitPercent - b.profitPercent,
            render: (value, stock) => renderColorText(stock.buyPrice, stock, 'profitPercent'),
        },

        {
            width: 50,
            title: t('EPS'),
            dataIndex: 'eps',
            key: 'eps',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.eps - b.eps,
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('BV'),
            dataIndex: 'bookvalue',
            key: 'bookvalue',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.bookvalue - b.bookvalue,
            render: (value) => value ? <span className="font-bold">{localeFormat(value / 1000)}</span> : <></>,
        },
        {
            width: 50,
            title: t('P/E'),
            dataIndex: 'pe',
            key: 'pe',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.pe - b.pe,
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('P/B'),
            dataIndex: 'pb',
            key: 'pb',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.pb - b.pb,
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('ROA'),
            dataIndex: 'roarecent',
            key: 'roarecent',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.roarecent - b.roarecent,
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 50,
            title: t('ROE'),
            dataIndex: 'roerecent',
            key: 'roerecent',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.roerecent - b.roerecent,
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 60,
            title: t('Vốn hóa'),
            dataIndex: 'marketcap',
            key: 'marketcap',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.marketcap - b.marketcap,
            render: (value) => value ? <span className="font-bold">{localeFormat(value / (1000 * 1000 * 1000))}</span> : <></>,
        },
        {
            width: 60,
            title: t('SLCPLH'),
            dataIndex: 'sharesoutstanding',
            key: 'sharesoutstanding',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.sharesoutstanding - b.sharesoutstanding,
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('SHNN'),
            dataIndex: 'foreignpercent',
            key: 'foreignpercent',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.foreignpercent - b.foreignpercent,
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 50,
            title: t('Free Float'),
            dataIndex: 'freefloatpercent',
            key: 'freefloatpercent',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.freefloatpercent - b.freefloatpercent,
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 60,
            title: t('Room NN còn lại'),
            dataIndex: 'foreignvolumeroom',
            key: 'foreignvolumeroom',
            showSorterTooltip: false,
            align: 'right',
            sorter: (a: InvestPerform, b: InvestPerform) => a.foreignvolumeroom - b.foreignvolumeroom,
            render: (value) => renderNormalValue(value),
        },
    ];

    return (
        <div style={{ marginTop: 3 }}>
            <Table
                {...props.tableProps}
                className="investment-performance-table"
                dataSource={data}
                columns={columns}
                scroll={{ y: tableContentHeight }}
                rowKey={(record: InvestPerform) => record.id}
                loading={loading}
                pagination={false}
                layoutHeight={tableContentHeight}
                size='small'
                bordered
            />
        </div>
    )
}

export default InvestmentPerformanceTable