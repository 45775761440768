import moment from "moment";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import useConfig from "../../../../../../contexts/useConfig";

const fakeData = require('./fakeData.json')

const generateProp = (arr, referenceObj) => {
    let returnObj = {};

    if (arr && arr.length > 0) {
        for (const [index, item] of arr.entries()) {
            const matchedItem = item.find(
                (e: any) => e.date === referenceObj.date,
            );
            returnObj[`value${index + 1}`] = matchedItem?.value;
        }
    }

    return returnObj;
};

export const groupComposedData = (arr) => {
    return arr && arr
        ? arr[0].map((item) => {
            return {
                ...item,
                ...generateProp(arr, item),
            };
        }) : [];
};

export const groupPieData = (arr, timeType, time, sort?: boolean) => {
    let formattedArr = [];
    arr.map((item) => {
        let timeFormat = '';
        let returnObj = {};
        if (timeType === 'year') timeFormat = 'YYYY';
        if (timeType === 'quarter') timeFormat = 'Q/YYYY';
        if (timeType === 'month') timeFormat = 'M/YYYY';

        const filterByTime = item.data.findIndex(
            (entry: any) =>
                moment(entry.date).format(timeFormat) === time,
        );

        if (item.data[filterByTime]) {
            returnObj = {
                name: item.name,
                value: item.data[filterByTime]?.value || null,
            }

            formattedArr.push(returnObj);
        }

        return formattedArr;
    });

    if (sort) {
        return _.orderBy(formattedArr, ['value'], ['desc']);
    }
    return formattedArr;
};

export const GDPChartList = (economicData) => {
    const { t } = useTranslation();
    const [config] = useConfig();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP và GDP/người'),
            data: groupComposedData([
                economicData.gdppercapital,
                economicData.gdpgrowthrateyearly
            ]),
            timeType: "year",
            YAxisUnit: "usd",
            secondYAxis: true,
            secondYAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [{ dataKey: "value1", name: t('GDP/Người'), yAxisType: "left", unit: ` ${t('USD')}` }],
                line: [{ dataKey: "value2", name: t('Tăng trưởng GDP'), yAxisType: "right", unit: "%",}],
            },
        },
        {
            chartType: "composed",
            title: t('GDP hàng quý'),
            data: economicData.gdpgrowthratequarter,
            timeType: "quarter",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Tăng trưởng GDP'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP & Tổng sản phẩm quốc nội GDP'),
            data: groupComposedData([
                economicData.gdp,
                economicData.gdpgrowthrateyearly
            ]),
            timeType: "year",
            YAxisUnit: t('Tỷ USD'),
            secondYAxis: true,
            secondYAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [{ dataKey: "value1", name: t('Tổng sản phẩm quốc nội'), yAxisType: "left", unit: ` ${t('Tỷ USD')}` }],
                line: [{ dataKey: "value2", name: t('Tăng trưởng GDP'), yAxisType: "right", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP - Q1'),
            data: economicData.gdpgrowthratequarter1,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                bar: [{ dataKey: "value", name: t('Tăng trưởng GDP - Q1'), yAxisType: "left", unit: "%" }],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP - Q2'),
            data: economicData.gdpgrowthratequarter2,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                bar: [{ dataKey: "value", name: t('Tăng trưởng GDP - Q2'), yAxisType: "left", unit: "%" }],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP - Q3'),
            data: economicData.gdpgrowthratequarter3,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                bar: [{ dataKey: "value", name: t('Tăng trưởng GDP - Q3'), yAxisType: "left", unit: "%" }],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP - Q4'),
            data: economicData.gdpgrowthratequarter4,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                bar: [{ dataKey: "value", name: t('Tăng trưởng GDP - Q4'), yAxisType: "left", unit: "%" }],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng GDP - 9T'),
            data: economicData.gdpgrowthrate9month,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                bar: [{ dataKey: "value", name: t('Tăng trưởng GDP - 9T'), yAxisType: "left", unit: "%" }],
                line: [],
                area: [],
            },
        },
        {
            chartType: "pie",
            title: `${('Cơ cấu tỉ trọng năm')} ${config.GDP_STRUCTURE}`,
            data: groupPieData([
                { name: t('Nông, lâm nghiệp, thuỷ sản'), data: economicData.gdpagricultureforestryfisheriespercent },
                { name: t('Công nghiệp, xây dựng'), data: economicData.gdpindustryconstructionpercent },
                { name: t('Dịch vụ'), data: economicData.gdptravelpercent },
                // { name: "Thuế sản phẩm", data: economicData.industrytaxproduct },
            ], "year", config.GDP_STRUCTURE),
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Cơ cấu tỉ trọng'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: t('PMI'),
            data: economicData.pmi,
            timeType: 'month',
            YAxisUnit: '',
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('PMI'), yAxisType: "left", unit: "" }],
            },
        },
    ];
};

export const CPIChartList = (economicData) => {
    const { t } = useTranslation();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: `${t('CPI')} (${t('Tháng')})`,
            data: economicData.cpimonthly,
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('CPI'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: `${t('CPI')} (${t('Năm')})`,
            data: economicData.cpiyearly,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('CPI'), yAxisType: "left", unit: "%" }],
            },
        },
    ]
};

export const InterestRateChartList = (economicData) => {
    const { t } = useTranslation();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: t('Tỷ giá mua vào của NHNN'),
            data: economicData.bankusdratemonthly,
            timeType: "month",
            YAxisUnit: "",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Tỷ Giá'), yAxisType: "left", unit: "" }],
            },
        },
        {
            chartType: "composed",
            title: `${t('Tăng trưởng tín dụng')} (${t('Năm')})`,
            data: economicData.creditgrowth,
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [{ dataKey: "value", name: t('Tăng trưởng tín dụng'), yAxisType: "left", unit: "%" }],
                line: [],
            },
        },
        {
            chartType: "composed",
            title: `${t('Tăng trưởng tín dụng')} (${t('Tháng')})`,
            data: economicData.creditgrowthmonthly,
            timeType: "month",
            YAxisUnit: "%",
            replaceMonthToQuarter: true,
            chartStructure: {
                area: [],
                bar: [{ dataKey: "value", name: t('Tăng trưởng tín dụng'), yAxisType: "left", unit: "%" }],
                line: [],
            },
        },
        {
            chartType: "composed",
            title: t('Lãi Suất Tái Cấp Vốn'),
            data: economicData.supplyingcapitalinterestratemonthly,
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Lãi Suất'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: t('Dự trữ ngoại hối'),
            data: economicData.foreignexchangereservesyearly,
            timeType: "year",
            YAxisUnit: "",
            chartStructure: {
                bar: [{ dataKey: "value", name: t('Dự trữ ngoại hối'), yAxisType: "left", unit: ` ${t('Triệu USD')}` }],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: t('Lãi Suất Liên Ngân Hàng 3 tháng'),
            data: economicData.interbankofferedratemonthly,
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Lãi Suất'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: t('Tỷ giá giao dịch thực tế trong năm USD/VND'),
            data: economicData.actualexchangeratemonthly,
            timeType: "month",
            YAxisUnit: "",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Tỷ giá'), yAxisType: "left", unit: "" }],
            },
        },
        {
            chartType: "composed",
            title: t('Tăng trưởng cung tiền'),
            data: groupComposedData([
                economicData.m2growthmonthly,
                economicData.depositgrowthmonthly,
                economicData.creditgrowthmonthly,
            ]).slice(-13),
            timeType: "quarter",
            YAxisUnit: "%",
            chartStructure: {
                bar: [
                    { dataKey: "value1", name: t('Tăng trưởng M2'), yAxisType: "left", unit: "%" },
                    { dataKey: "value2", name: t('Tăng trưởng huy động'), yAxisType: "left", unit: "%" },
                    { dataKey: "value3", name: t('Tăng trưởng tín dụng'), yAxisType: "left", unit: "%" }
                ],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: t('OMO'),
            data: economicData.omoratemonthly,
            timeType: 'month',
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: 'value', name: t('OMO'), yAxisType: "left", unit: '%' }],
            }
        },
        {
            chartType: "composed",
            title: t('Tín phiếu'),
            data: economicData.treasurybillsratemonthly,
            timeType: 'month',
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: 'value', name: t('Tín phiếu'), yAxisType: "left", unit: '%' }],
            }
        },
        {
            chartType: "composed",
            title: t('Giá trị tín phiếu đáo hạn và tín phiếu phát hành mới'),
            data: fakeData,
            timeType: 'day',
            YAxisUnit: "",
            secondYAxis: true,
            secondYAxisUnit: "%",
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'gtr', name: t('Giá trị ròng'), yAxisType: "left", unit: '' },
                ],
                line: [
                    { dataKey: 'gtdh', name: t('Giá trị đáo hạn'), yAxisType: "left", unit: '' },
                    { dataKey: 'gttt', name: t('Tổng lượng tín phiếu'), yAxisType: "left", unit: '' },
                ],
            }
        },
        {
            chartType: "composed",
            title: t('Giá trị và lãi suất tín phiếu NHNN phát hành'),
            data: fakeData,
            timeType: 'day',
            YAxisUnit: "",
            secondYAxis: true,
            secondYAxisUnit: "%",
            tooltipOutsideBoxPosition: 'top',
            chartStructure: {
                area: [],
                bar: [
                    { dataKey: 'gttt', name: t('Giá trị trúng thầu'), yAxisType: "left", unit: '' }
                ],
                line: [
                    { dataKey: 'lstt', name: t('LS trúng thầu'), yAxisType: "right", unit: '%' },
                ],
            }
        },
    ]
};

export const IndustryChartList = (economicData) => {
    const { t } = useTranslation();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: `${t('Tăng trưởng % toàn ngành công nghiệp MOM')} (${t('Tháng')})`,
            data: economicData.industrygrowthratemonthly,
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Tăng trưởng'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "composed",
            title: `${t('Tăng trưởng % toàn ngành công nghiệp YOY')} (${t('Tháng')})`,
            data: economicData.industrygrowthratemonthlyyoy,
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                area: [],
                bar: [],
                line: [{ dataKey: "value", name: t('Tăng trưởng'), yAxisType: "left", unit: "%" }],
            },
        },
        {
            chartType: "stackBar",
            title: `${t('Chỉ số công nghiệp')} (${t('Năm')})`,
            data: groupComposedData([
                economicData.industrialproductionindexyearly,
                economicData.waterindustryyearly,
                economicData.electricalindustryyearly,
                economicData.manufactoringindustryyearly,
                economicData.miningindustryyearly
            ]),
            timeType: "year",
            YAxisUnit: "%",
            chartStructure: {
                bar: [
                    { dataKey: "value2", name: t('Cung cấp nước, hoạt động quản lý và xử lý rác thải, nước thải'), yAxisType: "left", unit: "%" },
                    { dataKey: "value3", name: t('Sản xuất và Phân phối điện'), yAxisType: "left", unit: "%" },
                    { dataKey: "value4", name: t('Công nghiệp chế biến, chế tạo'), yAxisType: "left", unit: "%" },
                    { dataKey: "value5", name: t('Khai khoáng'), yAxisType: "left", unit: "%" },
                    { dataKey: "value1", name: t('Toàn ngành'), yAxisType: "left", unit: "%" }
                ],
                line: [],
                area: [],
            },
        },
        {
            chartType: "stackBar",
            title: `${t('Chỉ Số Sản Xuất Công Nghiệp MOM')} (${t('Tháng')})`,
            data: groupComposedData([
                economicData.industrygrowthratemonthly,
                economicData.waterindustrymonthly,
                economicData.electricalindustry,
                economicData.manufacturingindustry,
                economicData.miningindustry
            ]),
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                bar: [
                    { dataKey: "value2", name: t('Cung cấp nước, hoạt động quản lý và xử lý rác thải, nước thải'), yAxisType: "left", unit: "%" },
                    { dataKey: "value3", name: t('Sản xuất và Phân phối điện'), yAxisType: "left", unit: "%" },
                    { dataKey: "value4", name: t('Công nghiệp chế biến, chế tạo'), yAxisType: "left", unit: "%" },
                    { dataKey: "value5", name: t('Khai khoáng'), yAxisType: "left", unit: "%" },
                    { dataKey: "value1", name: t('Toàn ngành'), yAxisType: "left", unit: "%" }
                ],
                line: [],
                area: [],
            },
        },
        {
            chartType: "stackBar",
            title: `${t('Chỉ Số Sản Xuất Công Nghiệp YOY')} (${t('Tháng')})`,
            data: groupComposedData([
                economicData.industrygrowthratemonthlyyoy,
                economicData.waterindustrymonthlyyoy,
                economicData.electricalindustryyoy,
                economicData.manufacturingindustryyoy,
                economicData.miningindustryyoy
            ]),
            timeType: "month",
            YAxisUnit: "%",
            chartStructure: {
                bar: [
                    { dataKey: "value2", name: t('Cung cấp nước, hoạt động quản lý và xử lý rác thải, nước thải'), yAxisType: "left", unit: "%" },
                    { dataKey: "value3", name: t('Sản xuất và Phân phối điện'), yAxisType: "left", unit: "%" },
                    { dataKey: "value4", name: t('Công nghiệp chế biến, chế tạo'), yAxisType: "left", unit: "%" },
                    { dataKey: "value5", name: t('Khai khoáng'), yAxisType: "left", unit: "%" },
                    { dataKey: "value1", name: t('Toàn ngành'), yAxisType: "left", unit: "%" }
                ],
                line: [],
                area: [],
            },
        },
    ]
};

export const RetailChartList = (economicData) => {
    const { t } = useTranslation();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: `${t('Doanh số bán lẻ')} (${t('Tháng')})`,
            data: groupComposedData([
                economicData.retailmonthly,
                economicData.retailgrowthratemonthly,
            ]),
            timeType: "month",
            YAxisUnit: "",
            secondYAxis: true,
            secondYAxisUnit: "%",
            chartStructure: {
                bar: [{ dataKey: "value1", name: `${t('Bán Lẻ')} (${t('Tỷ VNĐ')})`, yAxisType: "left", unit: "" }],
                line: [{ dataKey: "value2", name: t('Tốc độ tăng trưởng'), yAxisType: "right", unit: "%" }],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: `${t('Doanh số bán lẻ')} (${t('Năm')})`,
            data: groupComposedData([
                economicData.retailyearly,
                economicData.commericalyearly,
                economicData.hotelyearly,
                economicData.travelyearly,
            ]),
            timeType: "year",
            YAxisUnit: t('Tỷ VNĐ'),
            chartStructure: {
                bar: [
                    { dataKey: "value1", name: `${t('Tổng')} (${t('Tỷ VNĐ')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value2", name: `${t('Thương nghiệp')} (${t('Tỷ VNĐ')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value3", name: `${t('Khách sạn nhà hàng')} (${t('Tỷ VNĐ')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value4", name: `${t('Du lịch & Dịch vụ')} (${t('Tỷ VNĐ')})`, yAxisType: "left", unit: "" }
                ],
                line: [],
                area: [],
            },
        },
    ]
};

export const TradeChartList = (economicData) => {
    const { t } = useTranslation();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: `${t('Xuất Nhập Khẩu - Cán Cân Thương Mại')} (${t('Tháng')})`,
            data: groupComposedData([
                economicData.exportmonthly,
                economicData.importmonthly,
                economicData.balanceoftrademonthly,
            ]),
            timeType: "month",
            YAxisUnit: t('Triệu USD'),
            secondYAxis: true,
            secondYAxisUnit: t('Triệu USD'),
            chartStructure: {
                bar: [
                    { dataKey: "value1", name: `${t('Xuất khẩu')} (${t('Triệu USD')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value2", name: `${t('Nhập khẩu')} (${t('Triệu USD')})`, yAxisType: "left", unit: "" }
                ],
                line: [{ dataKey: "value3", name: `${t('Cán cân thương mại')} (${t('Triệu USD')})`, yAxisType: "right", unit: "" }],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: `${t('Xuất Nhập Khẩu - Cán Cân Thương Mại')} (${t('Năm')})`,
            data: groupComposedData([
                economicData.exportyearly,
                economicData.importyearly,
                economicData.balanceoftradeyearly,
            ]),
            timeType: "year",
            YAxisUnit: t('Triệu USD'),
            secondYAxis: true,
            secondYAxisUnit: t('Triệu USD'),
            chartStructure: {
                bar: [
                    { dataKey: "value1", name: `${t('Xuất khẩu')} (${t('Triệu USD')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value2", name: `${t('Nhập khẩu')} (${t('Triệu USD')})`, yAxisType: "left", unit: "" }
                ],
                line: [{ dataKey: "value3", name: `${t('Cán cân thương mại')} (${t('Triệu USD')})`, yAxisType: "right", unit: "" }],
            },
        },
    ]
};

export const FDIChartList = (economicData) => {
    const { t } = useTranslation();
    if (!economicData) return [];

    return [
        {
            chartType: "composed",
            title: `${t('FDI')} (${t('Tháng')})`,
            data: groupComposedData([
                economicData.fdiregisteredmonthly,
                economicData.disbursedfdimonthly,
            ]),
            timeType: "month",
            YAxisUnit: t('Tỷ USD'),
            chartStructure: {
                bar: [
                    { dataKey: "value1", name: `${t('FDI Đăng Ký')} (${t('Tỷ USD')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value2", name: `${t('FDI Giải Ngân')} (${t('Tỷ USD')})`, yAxisType: "left", unit: "" }
                ],
                line: [],
                area: [],
            },
        },
        {
            chartType: "composed",
            title: `${t('FDI')} (${t('Năm')})`,
            data: groupComposedData([
                economicData.registeredfdiyearly,
                economicData.disbursedfdiyearly,
            ]),
            timeType: "year",
            YAxisUnit: t('Triệu USD'),
            chartStructure: {
                bar: [
                    { dataKey: "value1", name: `${t('FDI Đăng Ký')} (${t('Triệu USD')})`, yAxisType: "left", unit: "" },
                    { dataKey: "value2", name: `${t('FDI Giải Ngân')} (${t('Triệu USD')})`, yAxisType: "left", unit: "" }
                ],
                line: [],
                area: [],
            },
        },
    ]
};