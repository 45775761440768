import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import { useTranslation } from "react-i18next";
import { useReducedState } from "../../../../../services/utils";
import * as config from "../../../../../config";
import api from "../../../../../services/api";
import moment from "moment";


const FpointHistory = (props: any) => {
    const { t } = useTranslation();
    const { point } = props;

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const [transactions, setTransactions] = useState<NSApp.PointTransaction[]>([]);

    const fetchPointTransactions = useCallback(async () => {
        const [error, response]: any = await api.get(
            '/point/transactions',
            {
                page: pagination.current,
                limit: pagination.pageSize,
            },
        );

        if (error) {
            return;
        }

        const transactions = response.data.transactions;
        const _transactions = transactions.map((t: NSApp.PointTransaction) => {
            return {
                ...t,
                createdAt: moment(t.createdAt).format("DD/MM/YYYY")
            };
        });

        setTransactions(_transactions);

        setPagination({ total: response.data.pagination.total });
    }, [pagination.current, setPagination]); // eslint-disable-line

    useEffect(() => {
        fetchPointTransactions();
    }, [fetchPointTransactions]);

    const columns: any = [
        {
            title: t('Ngày'),
            dataIndex: 'createdAt',
        },
        {
            title: t('Nội dung'),
            dataIndex: 'description',
        },
        {
            title: t('Số point'),
            dataIndex: 'point',
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={transactions}
            rowKey={(r: NSApp.PointTransaction) => r?.id}
            pagination={{
                ...pagination,
                onChange: page => {
                    setPagination({ current: page });
                },
            }}
            summary={() => (
                <Table.Summary fixed>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>Tổng cộng: {point?.point}</Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
            )}
        />
    );
};

export default FpointHistory;
